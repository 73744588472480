<template>
  <a-spin :spinning="loading">
    <div class="exam-box wrapper page-wrap" v-cloak>
      <div class="flex top">
        <span>{{ exam_detail.exam_name }}</span>
        <div class="tag" v-if="exam_detail.exam_haspassed == 1">已通过</div>
      </div>
      <div class="icon-box">
        <div class="line"></div>
        <div class="line2"></div>
        <div class="icon-box-e">
          <img src="./img/tiem.png" />
          <div class="duration">{{ getTime(exam_detail.exam_duration) }}</div>
          <div class="value">考试时间</div>
        </div>
        <div class="icon-box-e">
          <img src="./img/num.png" />
          <div class="duration">
            {{ (exam_detail.exam_question_num || "") + "题" }}
          </div>
          <div class="value">题目数量</div>
        </div>
        <div class="icon-box-e">
          <img src="./img/score.png" />
          <div class="duration">
            {{ (exam_detail.exam_score || "") + "分" }}
          </div>
          <div class="value">试卷总分</div>
        </div>
      </div>
      <div class="detail">
        <div class="detail-e" style="margin-right: 60px">
          <div class="value">考试时间</div>
          <div class="key">
            {{
              (exam_detail.exam_start_time || "") +
              "—" +
              (exam_detail.exam_end_time || "")
            }}
          </div>
        </div>
        <div class="detail-e">
          <div class="value">通过分数</div>
          <div class="key">
            {{ (exam_detail.exam_pass_score || "") + "分" }}
          </div>
        </div>
        <div class="detail-e">
          <div class="value">考试次数</div>
          <div class="key">
            {{
              exam_detail.exam_limit == -1
                ? "不限"
                : (exam_detail.exam_limit|| "") + "次" 
            }}
          </div>
        </div>
      </div>
      <div class="introduction">
        <div class="text">考试说明</div>
      </div>
      <a-textarea
        class="tarea"
        style="
          font-size: 18px;
          color: #212531;
          opacity: 0.6;
          width: 100%;
          background-color: white;
        "
        :value="exam_detail.exam_introduction"
        disabled
        auto-size
      ></a-textarea>
      <div class="btn" @click="open">报名</div>
    </div>
    <a-modal
      :footer="null"
      title="考试报名"
      v-model:visible="visible"
      style="width: 500px"
      centered
    >
      <a-form>
        <a-form-item label="姓名" required>
          <a-input
            v-model:value="sendData.record_name"
            placeholder="请输入姓名"
          />
        </a-form-item>
        <a-form-item label="电话" required>
          <a-input
            v-model:value="sendData.record_phone"
            placeholder="请输入手机号"
          />
        </a-form-item>
        <a-form-item label="身份证">
          <a-input
            v-model:value="sendData.record_idcard"
            placeholder="请输入身份证"
          />
        </a-form-item>
      </a-form>
      <div class="modalbtn" @click="insert">报名</div>
    </a-modal>
  </a-spin>
</template>
<script setup>
import { getUserExamData, insertExamRecordData } from "@/api/main.js";
import { useStore } from "vuex";
import { ref, createVNode } from "vue";
import url from "@/utils/url.js";
import { encode, decode } from "js-base64";
import { useRoute, useRouter } from "vue-router";
import { message, Modal, notification } from "ant-design-vue";
const route = useRoute();
const visible = ref(false);
let query = decode(route.query?.t).split(",");
const store = useStore();
const loading = ref(true);
const exam_detail = ref({});
getUserExamData({ user_id: store.state.user.user_id, exam_id: query[0] }).then(
  (res) => {
    if (res.code == 200) {
      exam_detail.value = res.data.exam;
      console.log(exam_detail.value);
    } else {
      message.error(res.msg);
    }
    loading.value = false;
  }
);
const getTime = (v) => {
  if (!v) {
    return "";
  }
  if (v >= 3600) {
    let h = Math.floor(v / 3600);
    let m = Math.floor((v - h * 3600) / 60);
    let s = v - h * 3600 - m * 60;
    let mm = m == 0 ? "" : `${m}分`;
    return `${h}小时${mm}${s}秒`;
  } else if (v < 3600 && v >= 60) {
    let m = Math.floor(v / 60);
    let s = v - m * 60;
    return `${m}分${s}秒`;
  } else {
    return v + "秒";
  }
};
const sendData = ref({
  exam_id: query[0],
  user_id: store.state.user.user_id,
  record_name: null,
  record_phone: store.state.user.user_phone,
  record_idcard: null,
});
const open = () => {
  sendData.value.record_name = null;
  sendData.value.record_idcard = null;
  sendData.value.record_phone = store.state.user.user_phone;
  visible.value = true;
};
const e = ref(true);
const insert = () => {
  if (!sendData.value.record_name) {
    return message.error("请输入姓名");
  }
  if (!sendData.value.record_phone) {
    return message.error("请输入手机号");
  }
  if (!e.value) {
    return;
  }

  Modal.confirm({
    title: "提示",
    centered: true,
    content: createVNode(
      "div",
      { style: "color:red;" },
      "点击确认进入考试即消耗1次考试资格，中途请勿退出，全部完成交卷有效。是否确认开始考试？"
    ),
    onOk() {
      insertExamRecordData(sendData.value).then((res) => {
        console.log(res.data);
        loading.value = false;
        if (res.code == 200) {
          visible.value = false;
          notification["success"]({
            message: "报名成功",
            description: "即将开始答题！",
            duration:1
          });
          setTimeout(() => {
            url.replaceTo("/exam/examquestion", { t: encode(`${res.data.record_id},${exam_detail.value.exam_id},${exam_detail.value.exam_duration}`)});
          }, 1000);
        } else {
          message.error(res.msg);
        }
      });
    },
    onCancel() {
      console.log("Cancel");
    },
  });
};
</script>
<style lang="less" scoped>
.modalbtn {
  width: 300px;
  height: 46px;
  background-color: #1276cb;
  border-radius: 23px;
  line-height: 46px;
  text-align: center;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
  margin: 50px auto;
}
.exam-box {
  padding-top: 30px;
  .btn {
    width: 400px;
    height: 60px;
    background-color: #1276cb;
    border-radius: 30px;
    line-height: 60px;
    text-align: center;
    font-size: 20px;
    color: #ffffff;
    cursor: pointer;
    margin: 126px auto;
  }
  .introduction {
    width: 100%;
    .text {
      font-size: 18px;
      color: #212531;
      line-height: 1;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  .tarea {
    border: 0;
    outline: none;
    background-color: rgba(0, 0, 0, 0); // 透明背景
  }
  .detail {
    width: 100%;
    margin-top: 11px;
    display: flex;
    flex-wrap: wrap;
    .detail-e {
      width: 570px;
      height: 57px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px #dfdfdf solid;
      .value {
        font-size: 18px;
        color: #212531;
      }
      .key {
        font-size: 18px;
        color: #212531;
      }
    }
  }
  .top {
    width: 100%;
    font-size: 22px;
    font-weight: bold;
    color: #212531;
    margin-bottom: 30px;
    .tag {
      width: 81px;
      height: 23px;
      background-color: #1276cb;
      border-radius: 11px;
      font-size: 14px;
      text-align: center;
      line-height: 23px;
      color: #ffffff;
      margin-left: 37px;
    }
  }
  .icon-box {
    position: relative;
    width: 1200px;
    height: 240px;
    background-color: #f3f5f8;
    border-radius: 10px;
    display: flex;
    .line,
    .line2 {
      height: 84px;
      border-left: dashed 1px #000000;
      opacity: 0.2;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 400px;
    }
    .line2 {
      left: 800px !important;
    }
    .icon-box-e {
      width: 400px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .duration {
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0px;
        color: #212531;
        margin-top: 19px;
        line-height: 1;
      }
      .value {
        font-size: 18px;
        color: #212531;
        opacity: 0.7;
        margin-top: 14px;
        line-height: 1;
      }
      img {
        height: 44px;
        width: auto;
      }
    }
  }
}
</style>